<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 md4 sm4 lg4 class="text-left">
                <span class="heading">Top Rescuers</span>
              </v-flex>
              <v-flex xs12 sm5 md6 lg6 pt-2 pt-lg-0 pt-md-0 pt-sm-0>
                <v-text-field
                      :hide-details="true"
                       v-model="keyword"
                        append-icon="mdi-magnify"
                        label="Search By Name"
                        solo  clearable
                        dense flat outlined class="text-des"
                       style="font-family:sofiaProRegular;"
                      ></v-text-field>
              </v-flex>
              <v-flex
                xs12
              md2 sm3   lg2 pt-lg-0 pt-md-0 pt-sm-
                class="text-end"
                v-if="topcommonsnakes && topcommonsnakes.length > 0"
              >
                <v-btn
                  color="#427D2D"
                  class="downlink"
                  target="_blank"
                  @click="getExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: white;
                    "
                  >
                    Excel<v-icon color="white"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex xs12>
            <span
              style="color: red; font-family: poppinsregular; font-size: 13px"
              >Please select the main criterias for search.
            </span>
          </v-flex> -->
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs10 sm8 md5 lg3>
                <v-layout wrap>
                  <!-- <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="alldata"
                      label="All"
                      @change="checkUpdate()"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex> -->
                  <!-- <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="timeperiod"
                      label="Time Period"
                      class="small-checkbox no-space"
                      @change="updateAllCheckboxState"
                    ></v-checkbox>
                  </v-flex> -->
                  <!-- <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="bigsnakes"
                      label="District"
                      class="small-checkbox no-space"
                      @change="updateAllCheckboxState"
                    ></v-checkbox>
                  </v-flex> -->
                  
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm4 md3 lg3 class="subheading" v-if="timeperiod">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>From Date</span>
                        <v-text-field
                          v-model="fromdate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    md3
                    lg3
                    pl-lg-2
                    pl-sm-2
                    pl-md-2
                    class="subheading"
                    v-if="timeperiod"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>To Date</span>
                        <v-text-field
                          v-model="todate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    lg3
                    sm4
                    md3
                    v-if="bigsnakes"
                    class="subheading"
                    :pl-lg-2="timeperiod ? true : false"
                    :pl-md-2="timeperiod ? true : false"
                    :pl-sm-2="timeperiod ? true : false"
                  >
                    <span>Select District</span>
                    <v-select
                      :items="districts"
                      v-model="bigsnakeselect"
                      outlined
                      dense
                      hide-details="true"
                      :disabled="alldata"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  justify-start
                  v-if="timeperiod || bigsnakes || allsnakes || users"
                  pt-4
                >
                  <v-flex xs12 md2 sm2 lg1>
                    <v-btn
                      color="success"
                      depressed
                      @click="getData()"
                      :disabled="alldata"
                    >
                      <span>Submit</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 md2 sm2 lg1>
                    <v-btn
                      color="warning"
                      depressed
                      @click="reset()"
                      :disabled="alldata"
                    >
                      <span>Reset</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-5>
              <v-flex xs12 v-if="topcommonsnakes && topcommonsnakes.length > 0">
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablehead">Rescuer Code</th>
                          <th class="text-left tablehead">Name</th>
                          <th class="text-left tablehead">Phone</th>
                          <th class="text-left tablehead">Email</th>
                          <th class="text-left tablehead">Rescued Count</th>
                        </tr>
                      </thead>
                      <tbody class="tablesubheading">
                        <tr
                          v-for="(item, index) in topcommonsnakes"
                          :key="index"
                        >
                          <td style="white-space: nowrap;" class="tableitems">
                            <span v-if="item && item.rescuerCode">{{
                              item.rescuerCode
                            }}</span>
                            <span v-else>------</span>
                          </td>
                          <td style="white-space: nowrap;" class="tableitems">
                            <span v-if="item && item.name">{{
                              item.name
                            }}</span>
                            <span v-else>------</span>
                          </td>
                          <td style="white-space: nowrap;" class="tableitems">
                            <span v-if="item &&  item.phone">{{
                              item.phone
                            }}</span>
                            <span v-else>------</span>
                          </td>
                          <td style="white-space: nowrap;" class="tableitems">
                            <span v-if="item && item.email">{{
                              item.email
                            }}</span
                            ><span v-else>------</span>
                          </td>
                          <td style="white-space: nowrap;" class="tableitems">
                            <span v-if="item && item.count">{{
                              item.count
                            }}</span>
                            <span v-else>------</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pb-4 v-if="topcommonsnakes && topcommonsnakes.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              :total-visible="7"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-start
      v-if="topcommonsnakes && topcommonsnakes.length === 0"
    >
      <v-flex xs12 class="text-center" pl-5>
        <span class="nodata">No Data Found!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      currentPage: 1,
      Pagelength: 0,
      pages: 0,
      count: 20,
      datalist: [],
      topcommonsnakes: [],
      json_data: [],
      json_fields: {
        Username: "user.name",
        Email: "user.email",
        Phone: "user.phone",
        Count: "count",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "user.name",
        },
        { text: "Email", sortable: false, value: "user.email" },
        { text: "Phone", sortable: false, value: "user.phone" },
        { text: "Count", sortable: false, value: "count" },
      ],
      districts: [
        "All",
        "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
      alldata: true,
      timeperiod: false,
      bigsnakes: false,
      allsnakes: false,
      users: false,
      activePicker: null,
      fromdate: null,
      menu: false,
      activePickerto: null,
      todate: null,
      menu2: false,
      keyword:"",
      bigsnakeselect: "",
      allsnakelist: "",
      selecteduser: "",
      bigsnakestypes: [],
      situationlist: [
        "Inside the house",
        "Just outside the building",
        "Natural",
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePickerto = "YEAR"));
    },
    currentPage() {
      this.getData();
    },
    keyword() {
        this.currentPage=1
        this.getData();
      },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/top-rescuers/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // to: this.todate,
          // from: this.fromdate,
           keyword: this.keyword,
          count:this.count,
          page:this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.topcommonsnakes = response.data.data;
              this.json_data = response.data.data.topusers;
              this.Pagelength = Math.ceil(response.data.count / this.count);
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    checkUpdate() {
    if (this.alldata) {
      // If 'All' is checked, uncheck other checkboxes and reset the data
      this.timeperiod = false;
      this.bigsnakes = false;
      this.allsnakes = false;
      this.users = false;
      this.fromdate = null;
      this.todate = null;
      this.bigsnakeselect = "";
      this.allsnakelist = [];
      this.selecteduser = "";
      this.getData();
    }
  },

  // Updates the state of 'All' checkbox based on individual selections
  updateAllCheckboxState() {
    // If either 'timeperiod' or 'bigsnakes' is checked, uncheck 'All'
    if (this.timeperiod || this.bigsnakes) {
      this.alldata = false;
    }

    // If neither 'timeperiod' nor 'bigsnakes' is selected, check 'All'
    if (!this.timeperiod && !this.bigsnakes) {
      this.alldata = true;
    }
  },
    reset() {
      // Reset all checkboxes
      this.timeperiod = false;
      this.bigsnakes = false;
      this.allsnakes = false;
      this.users = false;
      this.alldata = true
      // Reset date pickers
      this.fromdate = null;
      this.todate = null;

      // Reset big snake selection
      this.bigsnakeselect = [];

      // Reset all snake list
      this.allsnakelist = [];

      // Reset user selection
      this.selecteduser = "";
      this.getData();
      // You can also trigger any other actions if needed
      // this.msg = "Fields have been reset";
      // this.showSnackBar = true;
    },
    // getExcel() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/operation/top-rescuers/list/download/excel",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     responseType: "blob",
    //     params: {
    //       district: this.district,
    //       from:this.fromdate,
    //       to: this.todate,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       const url = URL.createObjectURL(
    //         new Blob([response.data], {
    //           type: "application/vnd.ms-excel",
    //         })
    //       );
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "TopRescuers.xlsx");
    //       document.body.appendChild(link);
    //       link.click();
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/top-rescuers/list/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Topsnakes.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>
    